import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Heading, Text } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import OtoSim from '../../content/contact/otosim.mdx';
import NorthAmerica from '../../content/contact/northAmerica.mdx';
import International from '../../content/contact/international.mdx';
import GSA from '../../content/contact/gsa.mdx';
import RouteButton from 'components/routeButton';

const Contact = ({ data }) => (
  <Layout>
    <Head pageTitle={data.contactJson.title} />
    <Box fill="horizontal">
      <Box width="xlarge" alignSelf="center">
        <SectionTitle title="Contact" />
        <Box margin="small" width="xlarge" pad="small">
          <Heading level="2" size="small">
            General Inquiries
          </Heading>
          <Box
            direction="row-responsive"
            justify="center"
            gap="xlarge"
            alignSelf="center"
            pad="small"
          >
            <Box width="medium" margin={{ right: 'xlarge' }}>
              <OtoSim />
            </Box>
            <Box width="medium">
              <Text textAlign="center"> Not from North America?</Text>
              <RouteButton
                to="/international-distributors"
                label="International Distributors"
              />
            </Box>
          </Box>

          <Heading level="2" size="small">
            Sales Inquiries
          </Heading>
          <Box
            direction="row-responsive"
            justify="center"
            gap="medium"
            alignSelf="center"
            pad="small"
          >
            <Box width="small">
              <NorthAmerica />
            </Box>
            <Box width="medium">
              <International />
            </Box>
            <Box width="medium">
              <GSA />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
    }
  }
`;
