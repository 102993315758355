import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GSA`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
{`OtoSim Inc. is an authorized General Services Administration contract holder. For more information about our price list and schedule, please contact:`}</MDXTag>
<MDXTag name="p" components={components}>{`Jennifer Garcia`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
{`Tel: 210-908-0491`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
{`Email: `}<MDXTag name="a" components={components} parentName="p" props={{"href":"mailto:jennifer@otosim.com"}}>{`jennifer@otosim.com`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {};

  