import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`International Sales`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
{`OtoSim Inc. has a growing list of distributors covering a variety of countries outside of Canada and USA.  To find out more about distributors in your area, please check our list of distributors. If you cannot find the distributor in your region or to become an OtoSim distributor, please contact:`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: +1 647-792-7735`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
{`Email: `}<MDXTag name="a" components={components} parentName="p" props={{"href":"mailto:internationalsales@otosim.com"}}>{`internationalsales@otosim.com`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {};

  