import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`North America`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
{`Tel: 647-792-7735`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
{`Email: `}<MDXTag name="a" components={components} parentName="p" props={{"href":"mailto:sales@otosim.com"}}>{`sales@otosim.com`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {};

  