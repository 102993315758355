import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`OtoSim Inc.`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
{`555 University Ave, Room 8436`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
{`Toronto, ON M5G 1X8`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
{`Canada`}</MDXTag>
<MDXTag name="p" components={components}>{`Tel: 647-792-7735`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
{`Email: `}<MDXTag name="a" components={components} parentName="p" props={{"href":"mailto:info@otosim.com"}}>{`info@otosim.com`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {};

  